<template>
	<div>
		<v-card flat color="transparent">
			<v-card-title class="ml-0">
				<v-btn
					icon
					tile
					outlined
					text
					large
					class="mt-n2 ml-n4 rounded-lg"
					@click="$router.go(-1)"
				>
					<v-icon>
						mdi-chevron-left
					</v-icon>
				</v-btn>

				<h1 class="ml-4 text-title">
					Edit Profile
				</h1>

				<v-spacer></v-spacer>
			</v-card-title>
		</v-card>

		<v-card :color="`${ant}`" height="400px" class="mt-5" flat>

			<v-card-text>
				<v-row>
					<v-col>
						<v-list-item v-if="session">
							<v-list-item-avatar size="80" color="grey">
								<img
									:src="avatar"
									:alt="session.customer.nickname"
									v-if="avatarURl"
								/>
								<span v-else class='text-h3'>  {{ session.customer.nickname ? trimFirtTwo(session.customer.nickname) :  session.customer.id  }}</span>
							</v-list-item-avatar>
							<v-list-item-content class="mt-3">
								<v-list-item-title class="headline"> 
                  <v-btn color="red" v-if="avatarURl" :loading="loading" @click="deleteAvatar">
										<i class="far fa-trash-alt white--text"></i>
										<span class="ml-3">Delete Photo</span>
									</v-btn>
									<v-btn :color="primaryBlue" @click="show=true" v-else>
										<v-icon>mdi-upload</v-icon>
										<span class="ml-3">Upload Photo </span>
									</v-btn>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-col>
					<v-col>
						<v-alert :type="actionClass" dismissible v-if="message" @input="alertInput">
							{{ message }}
						</v-alert>
						<div class="mt-4">
							<label for="" class="">Nickname:</label>
							<v-text-field
							dense
							outlined
							v-model="nickname"
							:error-messages="error.nickname"
							@blur="update"
							>
							</v-text-field>
						</div>
					</v-col>
				</v-row>
			</v-card-text>

		</v-card>
    <UploadModal :show="show" @close="show=false"/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import colorMxn from '@/mixins/colorMxn';

export default {
	name: 'EditProfile',
	mixins: [colorMxn],
	components: {
    UploadModal: () => import('@/modules/Profile/components/uploadModal'),
	},
	data() {
		return {
			nickname: 'Misiko',
      avatar: null,
			session: null,
			actionClass: 'success',
			message: '',
			error: {},
      show: false,
      loading: false,
		}
	},
	computed: {
		...mapGetters(['getSession', 'getErrors']),
    avatarURl() {
      const url = this.avatar ? `${process.env.VUE_APP_BASE_URL}/avatar/download/${this.avatar}` : null;
      return url;
    }
	},
	watch: {
    getErrors(val) {
      this.error = val;
    },
    getSession(val) {
      this.nickname = val.customer.nickname ? val.customer.nickname : val.customer.id;
      this.avatar = val.customer.avatar;
    }
  },
	mounted() {
		const session = this.getSession
		this.session = session;
		this.nickname = session.customer.nickname ? session.customer.nickname : session.customer.id;
    this.avatar = session.avatar;
	},
	methods: {
		...mapActions(['performPutActions', 'refreshAuthToken']),
		async update() {
			this.error = '';
			const session = this.getSession;
			const payload = {
				nickname: this.nickname,
			}
			
			const fullPayload = {
				params: payload,
				endpoint: `/profile/update-customer/${session.customer.id}`
			}

			const response = await this.performPutActions(fullPayload);
			this.message = response.message;
			this.actionClass = response.status ? "success" : "error";
			if (response.status) {
				await this.refreshAuthToken();
			}
			
		},
    async deleteAvatar() {
      this.loading = true;
      const session = this.getSession;
			const payload = {
				avatar: null,
			}
			
			const fullPayload = {
				params: payload,
				endpoint: `/profile/update-customer/${session.customer.id}`
			}

			const response = await this.performPutActions(fullPayload);
			this.message = response.message;
			this.actionClass = response.status ? "success" : "error";
      this.loading = false;
			if (response.status) {
        this.avatar = null;
				await this.refreshAuthToken();
			}
    },
		trimFirtTwo (val) {
			const trimmed = val.match(/.{1,2}/g)[0];
			return trimmed.toUpperCase();
		},
		alertInput() {
			this.message = "";
		},
	}
}
</script>